<template>
    <div class="wrap">
        <b-container class="navtab">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
                <el-breadcrumb-item>{{$t('message.promore')}}</el-breadcrumb-item>
            </el-breadcrumb>
        </b-container>
        <!-- pc布局【商品分类布局】 -->
        <b-container class="pc-shop-list">
            <el-row>
                <el-col :span="4">
                    <div class="pc-label-tit" v-for="(item,index) in tabList" :key="index">
                        <span class="pc-label" :class="tabindex==index?'pc-cur':''" @click="getTab(item,index)">
                            {{languageName=='cn'?item.cn_title:''}}
                            {{languageName=='en'?item.en_title:''}}
                            {{languageName=='it'?item.it_title:''}}
                        </span>
                    </div>
                </el-col>
                <el-col :span="20">
                    <div class="pc-shop-group-box">
                        <div class="pc-shop-group-item" v-for="(item,index) in shopListdata" :key="index">
                            <div class="pc-shop-group-title">
                                {{languageName=='cn'?item.cn_title:''}}
                                {{languageName=='en'?item.en_title:''}}
                                {{languageName=='it'?item.it_title:''}}    
                            </div>
                            <div class="pc-shop-group-content">
                                {{languageName=='cn'?(item.cn_description?item.cn_description:''):''}}
                                {{languageName=='en'?(item.en_description?item.en_description:''):''}}
                                {{languageName=='it'?(item.it_description?item.it_description:''):''}}
                            </div>
                            <div class="pc-shop-group-list" v-for="(v,key) in dataList" :key="key" v-show="item.id==v.key">
                                <div  class="pc-shop-group-list-item" v-for="(val,k) in v.list" :key="k">
                                    <div class="pc-shop-group-list-item-img" @click="getShopInfo(val)">
                                        <img :src="val.goods_img" alt="">
                                    </div>
                                    <div class="pc-shop-group-list-item-tit">{{val.spect_name}}</div>
                                    <div class="pc-shop-group-list-item-txt" @click="getShopInfo(val)">
                                        <span>
                                            {{languageName=='cn'?val.cn_name:''}}
                                            {{languageName=='en'?val.en_name:''}}
                                            {{languageName=='it'?val.it_name:''}}
                                        </span>
                                    </div>
                                    <div class="pc-shop-group-list-item-car">
                                        <div class="pc-money">
                                            {{$t('message.m')}}
                                            {{val.moneyOne}}
                                        </div>
                                        <div class="pc-shopcar" @click="getAddShopCar(val)"><span class="pc-car"></span></div>
                                    </div>
                                </div>
                                <div class="clear"></div>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </b-container>
        <!-- wap布局【商品分类布局】 -->
        <b-container class="wap-shop-list">
            <div class="wap-shop-type">
                <div class="wap-shop-type-item" v-for="(item,index) in tabList" :key="index" @click="getTab(item,index)">
                    {{languageName=='cn'?(item.cn_title?item.cn_title:''):''}}
                    {{languageName=='en'?(item.en_title?item.en_title:''):''}}
                    {{languageName=='it'?(item.it_title?item.it_title:''):''}}
                    <span class="spbor" v-show="tabindex==index"></span>
                </div>
            </div>
            <b-row>
                <div class="wap-shop-group-box" v-for="(item,index) in shopListdata" :key="index">
                    <div class="wap-shop-group-item">
                        <div class="wap-shop-group-title">
                            {{languageName=='cn'?item.cn_title:''}}
                            {{languageName=='en'?item.en_title:''}}
                            {{languageName=='it'?item.it_title:''}}    
                        </div>
                        <div class="wap-shop-group-content">
                            {{languageName=='cn'?(item.cn_description?item.cn_description:''):''}}
                            {{languageName=='en'?(item.en_description?item.en_description:''):''}}
                            {{languageName=='it'?(item.it_description?item.it_description:''):''}}
                        </div>
                        <div class="wap-shop-group-list" v-for="(v,key) in dataList" :key="key" v-show="item.id==v.key">
                            <div  class="wap-shop-group-list-item" v-for="(val,k) in v.list" :key="k">
                                <div class="wap-shop-group-list-item-img" @click="getShopInfo(val)">
                                    <img :src="val.goods_img" alt="">
                                </div>
                                <div class="wap-shop-group-list-item-content">
                                    <div class="wap-shop-group-list-item-tit">{{val.spect_name}}</div>
                                    <div class="wap-shop-group-list-item-txt" @click="getShopInfo(val)">
                                        <span>
                                            {{languageName=='cn'?val.cn_name:''}}
                                            {{languageName=='en'?val.en_name:''}}
                                            {{languageName=='it'?val.it_name:''}}
                                        </span>
                                    </div>
                                    <div class="wap-shop-group-list-item-car">
                                        <div class="wap-money">
                                            {{$t('message.m')}}
                                            {{languageName=='cn'?val.cn_price:''}}
                                            {{languageName=='en'?val.en_price:''}}
                                            {{languageName=='it'?val.it_price:''}}
                                        </div>
                                    </div>
                                </div>
                                <div class="wap-shopcar" @click="getAddShopCar(val)"><span class="wap-car"></span></div>
                            </div>
                            <div class="clear"></div>
                        </div>
                    </div>
                </div>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import ElementUI from 'element-ui';
// import label from '@/json/shop.json'
import {yooz_lang,getLocalItem,delLocalItem} from '@/untils/common';
export default {
    name:'list',
    inject:['reload'],
    components:{
    },
    data(){
        return{
            token:'',
            languageName:'',
            languageObj:{},
            tabindex:0,
            tabList:[],
            dataListarr:[],
            shopListdata:[],
            dataList:[],
            userPople:'',//userPople:'company' 企业用户，数量10+（is_company）
        }
    },
    watch:{
        searchParams:function() {
            this.getTabFirstList();
        },
    },
    computed:{
        searchParams() {
            if(this.$route.query&&this.$route.query.type){
                var params = {
                    "id":this.$route.query.type
                };
                return params;
            }
        },
    },
    methods: {
        getTab(item,index){
            this.tabindex = index;
            this.getList(item);
        },
        // 左侧菜单数据
        getTabFirstList(){
            // 一级tab数据
            this.$http.api_goods_levelone().then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.tabList = res.data;
                            if(res.data.length>0){
                                // 二级列表数据
                                this.getList(res.data[this.tabindex]);
                            }
                        }
                    }else{
                        // ElementUI.Message({
                        //     message:res.message,
                        //     type:'error',
                        //     center: true,
                        //     offset:10,
                        // });
                        return false;
                    }
                }
            });
        },
        // 右侧列表数据
        getList(item){
            if(item){
                // 二级数据
                this.$http.api_goods_levelone_child({
                    typeid:item.id
                }).then((res)=>{
                    if(res){
                        if(res.code==1){
                            if(res.data){
                                this.shopListdata = res.data;
                                res.data.map((v)=>{
                                    if(v.child){
                                        if(v.id==v.child[0].typeid){
                                            console.log('3333333')
                                        }
                                    }else{
                                        // 商品数据
                                        this.getChild(v);
                                    }
                                });
                            }
                        }else{
                            // ElementUI.Message({
                            //     message:res.message,
                            //     type:'error',
                            //     center: true,
                            //     offset:10,
                            // });
                            return false;
                        }
                    }
                });
            }
        },
        // 数组去重
        unique(arr) {
            const res = new Map();
            return arr.filter((arr) => !res.has(arr.key) && res.set(arr.key, 1))
        },
        // 商品数据
        getChild(v){
            this.$http.api_goods_shoplist({
                typeid:v.pid,//typeid（顶级类型）、
                typetwoid:v.id,//（二级类型）、
                ishome:0,//（是否首页推荐）、
                page:1,//（页数）、
                size:0,//（每页条数默认10条值为0无分页）
                iscompany:this.userPople=='company'?1:0,//（是否为企业身份 1是0否）
            }).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            let prams = {};
                            prams.key = v.id;
                            prams.list = res.data;
                            let arr = [prams];
                            this.dataListarr = this.dataList.concat(arr);
                            this.dataListarr = this.unique(this.dataListarr);
                            this.dataListarr.map((v,k)=>{
                                v.list.map((i,key)=>{
                                    if(i.id){
                                        // 商品规格
                                        this.$http.api_goods_shopgsinfobyid({
                                            id:i.id
                                        }).then((response)=>{
                                            if(response){
                                                if(response.code==1){
                                                    if(response.data){
                                                        if(response.data.length>0){
                                                            i.spect_id = response.data[0].id;
                                                            i.spect_name = response.data[0].spect_name;
                                                            this.$set(this.dataListarr[k].list[key],i.spect_id,response.data[0].id);
                                                        }
                                                    }
                                                }else{
                                                    // ElementUI.Message({
                                                    //     message:response.message,
                                                    //     type:'error',
                                                    //     center: true,
                                                    //     offset:10,
                                                    // });
                                                    return false;
                                                }
                                            }
                                        });
                                    }
                                    if(this.languageName=='cn'){
                                        if(i.cn_price){
                                            if(i.discounts!=0){
                                                i.money = i.cn_price*((i.discounts)/100);//100-
                                                i.moneyOne = i.money.toFixed(2);
                                            }else{
                                                i.moneyOne = i.cn_price;
                                            }
                                        }else{
                                            i.cn_price = 0;
                                            i.moneyOne = i.cn_price;
                                        }
                                    }else if(this.languageName=='en'){
                                        if(i.en_price){
                                            if(i.discounts!=0){
                                                i.money = i.en_price*((i.discounts)/100);//100-
                                                i.moneyOne = i.money.toFixed(2);
                                            }else{
                                                i.moneyOne = i.en_price;
                                            }
                                        }else{
                                            i.en_price = 0;
                                            i.moneyOne = i.en_price;
                                        }
                                    }else if(this.languageName=='it'){
                                        if(i.it_price){
                                            if(i.discounts!=0){
                                                i.money = i.it_price*((i.discounts)/100);//100-
                                                i.moneyOne = i.money.toFixed(2);
                                            }else{
                                                i.moneyOne = i.it_price;
                                            }
                                        }else{
                                            i.it_price = 0;
                                            i.moneyOne = i.it_price;
                                        }
                                    }
                                });
                            });
                            this.dataList = this.dataListarr;
                        }
                    }else{
                        // ElementUI.Message({
                        //     message:res.message,
                        //     type:'error',
                        //     center: true,
                        //     offset:10,
                        // });
                        return false;
                    }
                }
            });
        },
        // 查看商品详情
        getShopInfo(item){
            if(item){
                this.$router.push({path:'/info',query:{shopid:item.id}});
            }
        },
        // 添加到购物车
        getAddShopCar(item){
            if(this.token&&item){
                let params = {
                    token:this.token,//（用户token）、
                    goods_id:item.id,//（商品id）、
                    goods_no:this.userPople=='company'?10:1,//（商品数量）、
                    is_delete:0,//（是否删除 1是 0否）、
                    is_add:1,//（是否添加 1是 0否）、
                    is_edit:0,//（是否 编辑 1是 0否）
                    goods_spect_id:item.spect_id//(商品规格id)
                }
                this.$http.api_order_editcart(params).then((res)=>{
                    if(res){
                        if(res.code==1){
                            if(res.data){
                                ElementUI.Message({
                                    message:this.languageObj.carsuccesstip,
                                    type:'success',
                                    center: true,
                                    offset:10,
                                });
                            }
                        }else if(res.code==-1){
                            // this.reload();
                            this.$store.commit('setLoginNameStatus', {});
                            delLocalItem('login_user_info');
                            this.$root.Bus.$emit('userInfoClick', {});
                            this.$store.commit('setLoginStatus',true);
                            this.$router.push({path:'/'});
                        }else{
                            ElementUI.Message({
                                message:res.message,
                                type:'error',
                                center: true,
                                offset:10,
                            });
                            return false;
                        }
                    }
                });
            }else{
                this.$store.commit('setLoginStatus',true);
            }
        },
    },
    created(){
        this.languageName = localStorage.lang?localStorage.lang:yooz_lang;
        if(this.languageName=='cn'){
            this.languageObj = {
                "carsuccesstip":"加入购物车成功",
            }
        }else if(this.languageName=='en'){
            this.languageObj = {
                "carsuccesstip":"successfully Added to Cart",
            }
        }else if(this.languageName=='it'){
            this.languageObj = {
                "carsuccesstip":'Aggiunto al carrello',
            }
        }
        let login_user_info = this.$store.state.data.loginNameStatus.family_name?this.$store.state.data.loginNameStatus:(getLocalItem('login_user_info')?getLocalItem('login_user_info'):null);
        if(login_user_info){
            // 再次判断是否是企业用户(是否企业身份 1是 0否)
            if(login_user_info.is_company==1){
                this.userPople = 'company'
            }
        }
        let token = getLocalItem('login_user_info')&&getLocalItem('login_user_info').token?getLocalItem('login_user_info').token:'';
        if(token){
            this.token = token;
        }
        let routes = this.$route.query;
        if(routes){
            this.tabindex = routes.type
        }
        this.getTabFirstList();
    }
}
</script>
<style lang="scss" scoped>
.clear{
    clear: both;
}
.navtab{
    padding:20px 10px 10px;
    
}
.pc-shop-list{
    padding:20px 10px 0;
    .pc-label-tit{
        padding:20px 0 0;
        
        .pc-label{
            cursor: pointer;
            display: inline-block;
            font-size:16px;
            color: #616166;
            margin: 0 10px;
            padding:0 10px 0 20px;
            vertical-align: middle;
        }
        .pc-cur{
            color: #1F73B7;
            border-left:2px solid #1F73B7;
        }
    }
}
.pc-shop-group-box{
    padding:20px 10px 0;
    .pc-shop-group-item{
        border-top:1px solid #DDE0ED;
        .pc-shop-group-title{
            font-size:20px;
            font-weight: bold;
            padding:20px 0 10px;
        }   
        .pc-shop-group-content{
            width:70%;
            padding:10px 0;
        }
        .pc-shop-group-list{
           
            .pc-shop-group-list-item{
                padding:20px;
                width:33%;
                float: left;
                cursor: pointer;
                .pc-shop-group-list-item-img{
                    width:100%;
                    height:260px;
                    img{
                        height: 100%;
                        width:100%;
                    }
                }
                .pc-shop-group-list-item-tit{
                    font-size:20px;
                    padding:10px 0 0;
                    font-weight: bold;
                }
                .pc-shop-group-list-item-txt{
                    padding:10px 0;
                    color: #464646;
                    span{
                        color: #464646;
                    }
                }
                .pc-shop-group-list-item-car{
                    display: flex;
                    -webkit-box-orient: horizontal;
                    padding:10px 0;
                    .pc-money{
                        display: flex;
                        width: 50%;
                        
                    }
                    .pc-shopcar{
                        display: flex;
                        width: 50%;
                        justify-content: flex-end;
                        .pc-car{
                            display: inline-block;
                            width: 25px;
                            height:25px;
                            background: url(~@/assets/images/icon_shopcar.png) no-repeat left top;
                            background-size:100% 100%;
                        }
                    }
                }
            }
        }
    }
}
/deep/.el-breadcrumb__inner.is-link{
    color: #1f73b7;
    font-weight: normal;
}
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner, .el-breadcrumb__item:last-child .el-breadcrumb__inner a, .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover, .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover{
    color: #95959e;
}

@media screen and (min-width: 1200px) {
    .wrap{
        .wap-shop-list{display: none;}
    }
}
@media screen and(min-width: 960px) and (max-width: 1199px) {
    .wrap{
        .wap-shop-list{display: none;}
        .pc-shop-group-box .pc-shop-group-item .pc-shop-group-list .pc-shop-group-list-item .pc-shop-group-list-item-img{
            height:190px;
        }
    }
}
@media screen and(min-width: 768px) and (max-width: 959px) {
    .wrap{
        .wap-shop-list{display: none;}
        .pc-shop-group-box .pc-shop-group-item .pc-shop-group-list .pc-shop-group-list-item .pc-shop-group-list-item-img{
            height:150px;
        }
    }
}
@media screen and(min-width: 480px) and (max-width: 767px) {
    .wrap{
        .pc-shop-list{display: none;}
        .wap-shop-list{
            background: #fff;
            padding: 0;
            .wap-shop-type{
                padding:0;
                margin: 0;
                display: flex;
                -webkit-box-orient: horizontal;
                .wap-shop-type-item{
                    display: flex;
                    width: 50%;
                    cursor: pointer;
                    padding:20px 20px 10px;
                    margin: 0;
                    text-align: center;
                    justify-content: center;
                    
                    position: relative;
                    color: #222;
                    .spbor{
                        position: absolute;
                        left:50%;
                        top:100%;
                        transform: translate(-50%,-50%);
                        height:2px;
                        width:80px;
                        background: #222;
                    }
                }
            }
            .row{padding: 0;margin: 0;}
            .wap-shop-group-box{
                padding:0px 10px 0;
                width: 100%;
                .wap-shop-group-item{
                    width: 100%;
                    border-top:1px solid #DDE0ED;
                    .wap-shop-group-title{
                        
                        font-weight: bold;
                        padding:10px 0 0px;
                    }   
                    .wap-shop-group-content{
                        width:100%;
                        padding:0px 0 0;
                        
                    }
                    .wap-shop-group-list{
                        .wap-shop-group-list-item{
                            padding:20px 0 0;
                            width:100%;
                            cursor: pointer;
                            display: flex;
                            -webkit-box-orient: horizontal;
                            .wap-shop-group-list-item-img{
                                width:30%;
                                height:100px;
                                img{
                                    height: 100%;
                                    width:100%;
                                }
                            }
                            .wap-shop-group-list-item-content{
                                width:60%;
                                padding-left: 10px;
                                .wap-shop-group-list-item-tit{
                                    font-size:16px;
                                    font-weight: bold;
                                }
                                .wap-shop-group-list-item-txt{
                                    color: #464646;
                                }
                                .wap-shop-group-list-item-car{
                                    display: flex;
                                    -webkit-box-orient: horizontal;
                                    padding:10px 0;
                                    .wap-money{
                                        display: flex;
                                        width: 50%;
                                        
                                    }
                                    
                                }
                            }
                            .wap-shopcar{
                                display: flex;
                                width: 10%;
                                justify-content: flex-end;
                                align-items: center;
                                .wap-car{
                                    display: inline-block;
                                    width: 25px;
                                    height:25px;
                                    background: url(~@/assets/images/icon_shopcar.png) no-repeat left top;
                                    background-size:100% 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 479px) {
    .wrap{
        .pc-shop-list{display: none;}
        .wap-shop-list{
            background: #fff;
            padding: 0;
            .wap-shop-type{
                padding:0;
                margin: 0;
                display: flex;
                -webkit-box-orient: horizontal;
                .wap-shop-type-item{
                    display: flex;
                    width: 50%;
                    cursor: pointer;
                    padding:20px 0 10px;
                    margin: 0;
                    text-align: center;
                    justify-content: center;
                    
                    position: relative;
                    color:#222;
                    .spbor{
                        position: absolute;
                        left:50%;
                        top:100%;
                        transform: translate(-50%,-50%);
                        height:2px;
                        width:80px;
                        background: #222;
                    }
                }
            }
            .row{padding: 0;margin: 0;}
            .wap-shop-group-box{
                padding:0px 10px 0;
                width: 100%;
                .wap-shop-group-item{
                    width: 100%;
                    border-top:1px solid #DDE0ED;
                    .wap-shop-group-title{
                        
                        font-weight: bold;
                        padding:10px 0 0px;
                    }   
                    .wap-shop-group-content{
                        width:100%;
                        padding:0px 0 0;
                        
                    }
                    .wap-shop-group-list{
                        .wap-shop-group-list-item{
                            padding:20px 0 0;
                            width:100%;
                            cursor: pointer;
                            display: flex;
                            -webkit-box-orient: horizontal;
                            .wap-shop-group-list-item-img{
                                width:30%;
                                height:100px;
                                img{
                                    height: 100%;
                                    width:100%;
                                }
                            }
                            .wap-shop-group-list-item-content{
                                width:60%;
                                padding-left: 10px;
                                .wap-shop-group-list-item-tit{
                                    font-size:16px;
                                    font-weight: bold;
                                }
                                .wap-shop-group-list-item-txt{
                                    color: #464646;
                                }
                                .wap-shop-group-list-item-car{
                                    display: flex;
                                    -webkit-box-orient: horizontal;
                                    padding:10px 0;
                                    .wap-money{
                                        display: flex;
                                        width: 50%;
                                        
                                    }
                                    
                                }
                            }
                            .wap-shopcar{
                                display: flex;
                                width: 10%;
                                justify-content: flex-end;
                                align-items: center;
                                .wap-car{
                                    display: inline-block;
                                    width: 25px;
                                    height:25px;
                                    background: url(~@/assets/images/icon_shopcar.png) no-repeat left top;
                                    background-size:100% 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>